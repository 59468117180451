<template>
  <div :style="styleObj">
    <slot/>
  </div>
</template>

<script>
	export default {
		props: {
			bgclr: String,
			borderclr: String
		},
		data(){
			return {
				styleObj: {
					'background-color': '#' + this.bgclr,
					'border': '2px solid #' + this.borderclr
				}
			}
		}
	}
</script>

<style scoped>
  
	div {

		color: black;
		border-radius: 10px;
		width: 100%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		padding-top: 10px;
		padding-bottom: 12px;
		
  }

</style>