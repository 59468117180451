<template>
  <div id="FooterDesign">
    <table>
      <th>
        <router-link to="/impressum">Impressum</router-link>
      </th>
    </table>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

  #FooterDesign {

    width: 80%;
    height: 100%;
    background-color: #b4ffb4;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
    display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;

  }

  a {

    font-size: 1.3rem;

  }
  
  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }
  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

</style>