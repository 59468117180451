<template>

  <image-viewer @close="hideDialog" :open="visible" :ordnerpath="ordnerpath" v-bind:top="getTop()" v-bind:zoom="getZoom()" />

  <div id="teicheMain">
    <h1>Gewässer</h1>
    <br/>
    <h3>Vereins Gew&auml;sser</h3>
    <br/>
    <div id="vereinsGewaesser">
    <ul>
      <li><a @click="showSchlosspark">Schlosspark</a></li>
      <li><a @click="showSchneiderMuehlenteich">Schneider M&uuml;hlenteich</a></li>
      <li><a @click="showBackumerTeich">Backumer Teich</a></li>
    </ul>
    </div>
    <br/>
    <h3>Landesfischerei Gew&auml;sser</h3>
    <br/>
    <div id="landesfischereiTeiche">
      <ul>
        <li>Dortmund-Ems-Kanal</li>
        <li>Rhein-Herne-Kanal</li>
        <li>Wesel-Datteln-Kanal</li>
        <li>Datteln-Hamm-Kanal</li>
        <li>Lippe Nordufer und S&uuml;dufer</li>
        <li>Holtwicker See</li>
        <li>Bierder See I und II</li>
        <li>Friller See</li>
        <li>Offlumer See</li>
        <li>Wichumer See bei Heek</li>
        <li>Gew&auml;sser Ochtrup-Weiner (Nienborger Damm)</li>
        <li>Gew&auml;sser L&uuml;chtringen</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ImageViewer from '../modules/ImageViewer.vue'

export default {
  components: {
    ImageViewer
  },
  data() {
    return {
      picture1: '',
      picture2: '',
      picture3: '',
      ordnerpath: '',
      visible: false,
    }
  },
  methods: {
    showSchlosspark() {

      this.ordnerpath = 'Schlosspark';
      this.visible = true;

    },
    showSchneiderMuehlenteich() {

      this.ordnerpath = 'Schneider_muehlenteich';
      this.visible = true;

    },
    showBackumerTeich() {

      this.ordnerpath = 'Backumer_Teich';
      this.visible = true;

    },
    hideDialog() {

      this.ordnerpath = '';
      this.visible = false;

    },
    getTop() {

        console.log(window.innerWidth)

        return window.innerWidth>=600?{
            top: '30vh'
        }: {
            top: '10vh'
        };

    },
    getZoom() {

        return window.innerWidth>=1200?true:false;

    }
  }
}
</script>

<style scoped>

  li,a {
		user-select: text;
  }

  h3 {

    color: #1100ff;

  }

  h1, h3 {

    text-align: center;

  }

  ul {

    list-style-type: square;
    color: #000000;
    line-height: clamp(1.9rem, 2vw, 2.4rem);

  }

  #teicheMain {

    /*display: flex;*/
    top: 0rem;
    bottom: 0rem;
    height: 100%;
    background-color: transparent;

  }

  #vereinsGewaesser {

    width: clamp(13rem, 15vw, 14rem);
    margin-left: auto;
    margin-right: auto;

  }

  #landesfischereiTeiche {

    width: clamp(13rem, 15vw, 14rem);
    margin-left: auto;
    margin-right: auto;

  }

  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }
  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

</style>
