<template>
  <div id="header">
    <Header></Header>
  </div>
  <div id="bufferDiv" />
  <div id="routerView">
    <div id="scroll-container">
      <router-view/>
    </div>
  </div>
  <div id="bufferDivBottom" />
  <div id="footer">
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './modules/Header.vue';
import Footer from './modules/Footer.vue';

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>

  * {
    margin: 0;
    padding: 0;
    /* No Select \/ */
    -ms-user-select: None;
		-moz-user-select: None;
		-webkit-user-select: None;
		user-select: None;
    /* /\ */
    /* Font Style \/ */
    font-family: Arial;
    /* /\ */
  }

  html, body {

    height: 100%;

  }

  #app {

    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;

  }
 
  a {

    color: black;
    text-decoration: none;

  }

  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }

  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }


</style>

<style scoped>

  #header {
    width: 100%;
    height: 5%;
    position: fixed;
    top: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;
  }

  #routerView {
    /* 
    
		width: 80%;
    position: absolute;
    top: 5.5%;
    bottom: 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;
    background-color: #00ffaa;
    margin-left: auto;
    margin-right: auto;
    
    */

    width: 78vw;
    position: absolute;
    top: 5.5%;
    bottom: 5%;
		border-radius: 10px;
    background-color: #00ffaa;
    left: 10vw;
    right: 10vw;
    padding-top: 1rem;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 1.5rem;

    display: flex;
    justify-content: center;
    align-content: center;
		background-repeat: no-repeat;
		background-position: center center;

  }

  #scroll-container {

    overflow-y: auto;
    scroll-behavior: smooth;
    
    width: 100%;

  }

  #bufferDiv {

    height: 6%;

  }

  #bufferDivBottom {

    height: 8%;

  }

  #footer {
    width: 100%;
    height: 4%;
    position: fixed;
    bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;
  }

</style>