<template>

  <div v-if="open" class="backdrop" @click="$emit('close')"></div>

  <div id="transitionModal">
    <transition name="modal">
      <dialog open v-if="open" v-bind:style="top">
        <div id="viewerDesign">
          <div id="images">
            <img :style="image1Style" @click="image1toggle" :src="getImage('1')" />
            <img :style="image2Style" @click="image2toggle" :src="getImage('2')" />
            <img :style="image3Style" @click="image3toggle" :src="getImage('3')" />
          </div>
        </div>
      </dialog>
    </transition>
  </div>

</template>

<script>
export default {
  props: ['open', 'ordnerpath', 'top', 'zoom'],
  emits: ['close'],
  data(){
    return {
      image1Style: {
        height: '10rem'
      },
      image2Style: {
        height: '10rem'
      },
      image3Style: {
        height: '10rem'
      },
      image1Click: false,
      image2Click: false,
      image3Click: false,
      imageClick: false
    }
  },
  methods: {
    getImage(imageNumber) {

      return require('../assets/' + this.ordnerpath + '/00' + imageNumber + '.jpg');
      
    },
    image1toggle() {

      if(!(this.zoom))
          return;

      if(this.image1Click) {

        this.image1Click = false;
        this.imageClick = false;
        this.image1Style.transform = '';
        this.image1Style.cursor = '';

      }else {

        if(this.imageClick) {
          if(this.image2Click) {
            this.image2toggle();
          } else if(this.image3Click) {
            this.image3toggle();
          }
        }

        this.imageClick = true;

        this.image1Click = true;
        this.image1Style.transform = 'scale(2.7, 2.7)';
        this.image1Style.cursor = 'zoom-out'

      }

    },
    image2toggle() {

      if(!(this.zoom))
          return;

      if(this.image2Click) {

        this.image2Click = false;
        this.imageClick = false;
        this.image2Style.transform = '';
        this.image2Style.cursor = '';

      }else {

        if(this.imageClick) {
          if(this.image1Click) {
            this.image1toggle();
          } else if(this.image3Click) {
            this.image3toggle();
          }
        }

        this.imageClick = true;

        this.image2Click = true;
        this.image2Style.transform = 'scale(2.7, 2.7)';
        this.image2Style.cursor = 'zoom-out'

      }

    },
    image3toggle() {

      if(!(this.zoom))
          return;

      if(this.image3Click) {

        this.image3Click = false;
        this.imageClick = false;
        this.image3Style.transform = '';
        this.image3Style.cursor = '';

      }else {

        if(this.imageClick) {
          if(this.image1Click) {
            this.image1toggle();
          } else if(this.image2Click) {
            this.image2toggle();
          }
        }

        this.imageClick = true;

        this.image3Click = true;
        this.image3Style.transform = 'scale(2.7, 2.7)';
        this.image3Style.cursor = 'zoom-out'

      }

    }
  },
  watch: {
    ordnerpath() {
      if(this.ordnerpath === '') {

        if(this.imageClick) {
          if(this.image1Click){
            this.image1toggle();
          }else if(this.image2Click) {
            this.image2toggle();
          }else if(this.image3Click) {
            this.image3toggle();
          }
        }

      }
    }
  }
}
</script>

<style scoped>

  img:hover {

    cursor: zoom-in;

  }

  img {

    border: 2px solid black;
    border-radius: 5px;
    margin: 5px;
    transition: transform 0.2s;
    
  }

  #closeTexture {

    background-color: lightcoral;
    width: auto;
    height: 100%;
    display: flex;
    
  }

  #transitionModal{

    display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;

  }

  #images {

    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-bottom: 0rem;

    /* background-color: lightgreen; */

  }

  #viewerDesign {

    width: auto;
    height: 100%;
    display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;

    /* background-color: lightblue; */

  }

  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.75);
  }

  dialog {
    position: fixed;
    top: 30vh;
    width: auto;
    height: auto;
    left: auto;
    right: auto;
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    background-color: white;
    z-index: 100;
    border: none;
    /* animation: modal 0.3s ease-out forwards; */
  }

  .modal-enter-active {
    animation: modal 0.3s ease-out;
  }

  .modal-leave-active {
    animation: modal 0.3s ease-in reverse;
  }

  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-50px) scale(0.9);
    }

    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

</style>