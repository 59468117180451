<template>
  <div id="design">

    <h1>Kontakt</h1>

    <br/>
    
    <contact-item posten="Vorsitzender" name="Kim-Robin Bielitz" email="K.Bielitz@Fischereiverein-Herten.de" telefon="+4917640593912" />
    <contact-item posten="Stellvertretender Vorsitzender" name="Bartolom&auml;us Raniewicz" email="R.Raniewicz@Fischereiverein-Herten.de" />
    <contact-item posten="Geschäftsführerin" name="Christine Peters" email="C.Peters@Fischereiverein-Herten.de" />
    <br/><br/>
    <h2>Download</h2>
    <download-item link="https://fischereiverein-herten.de/aufnahmeformular" title="Aufnahmeformular"/>

  </div>
</template>

<script>
import ContactItem from '../modules/ContactItem.vue';
import DownloadItem from '../modules/DownloadItem.vue';
export default {
  components: {
    ContactItem,
    DownloadItem
  }
}
</script>

<style scoped>

  a {

    color: black;
    text-decoration: none;

  }

  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }

  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

  #design {

    top: 0rem;
    bottom: 0rem;
    height: 100%;
    text-align: center;

  }

</style>