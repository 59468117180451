<template>
    <div v-if="events.length">
    <single-event-item class="eventItem" v-for="eventData in events" v-bind:name="eventData.name" v-bind:place="eventData.place" v-bind:date="eventData.date"
                       v-bind:start_time="eventData.start_time" v-bind:google_map_place="eventData.google_map_place"></single-event-item>
    </div>
    <div v-else>
        Keine Termine verfügbar
    </div>
</template>

<script>
import SingleEventItem from "@/modules/eventModules/SingleEventItem.vue";
// import eventsData from "../../../public/assets/events.json";

export default {
    name: "EventsView",
    components: {SingleEventItem},
    data() {
        return {
            events: {}
        }
    },
    methods: {
        async getEvents() {

            var logFile = await fetch("assets/events.json")
            var logfile2 = await logFile.text();
            this.events = JSON.parse(logfile2).events;
            return JSON.parse(logfile2).events;

            // return this.events;

        }
    },
    async beforeMount() {
        await this.getEvents()
    }
}
</script>

<style scoped>
  .eventItem {
      margin-bottom: 1vh;
  }
</style>