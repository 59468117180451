import { createRouter, createWebHistory } from 'vue-router';

import Home from './sites/Home.vue'
import Contact from './sites/Contact.vue'
import Impressum from './sites/Impressum.vue'
import Events from "./sites/Events.vue";
import Teiche from './sites/Teiche.vue'
import ImageViewer from './modules/ImageViewer.vue'
import Test from "./test/TestEvents.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/home', component: Home },
    { path: '/contact', component: Contact },
    { path: '/termine', redirect: '/events' },
    { path: '/events', component: Events },
    { path: '/impressum', component: Impressum },
    { path: '/gewaesser', component: Teiche},
    { path: '/image', component: ImageViewer },
    { path: '/test', component: Test}
  ]
});

export default router;