<template>
  <h2>Impressum</h2><br/>

  <h4>Angaben gem&auml;ß § 5 TMG</h4><br/>

  <p>
    Fischerei-Verein Herten e.V.<br/>
    Margenboomstraße 15a<br/>
    45701 Herten<br/>
    <br/>
    Vertreten durch den 1. Vorsitzenden: Kim-Robin Bielitz<br/>
    <br/>
    <b>Kontakt:</b><br/>
    Telefon: <a href="tel:017640593912">0176 40593912</a><br/>
    E-Mail: <a href="mailto:Info@Fischereiverein-Herten.de">Info@Fischereiverein-Herten.de</a><br/>
    <br/>
    <b>Vereinsregister:</b><br>
    Amtsgericht Recklinghausen VR 722
    <br/><br/>
  </p>
  <h4>Haftung f&uuml;r Inhalte</h4><br/>
  <p>
    Als Diensteanbieter sind wir gem&auml;ß § 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.<br/>
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/>
    <br/>
  </p>
  <h4>Urheberrecht</h4><br/>
  <p>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/>
    <br/>
  </p>
  <h4>Datenschutz</h4><br/>
  <p>
    Es gilt die europ&auml;ische Datenschutzgrundverordnung (DSGVO).<br/>
  </p>
</template>

<style scoped>

  p, a, b, h4{
		user-select: text;
  }

  h2 {

    text-align: center;

  }

  #impressumDesing {

    width: 80%;
    display: flex;
    top: 0rem;
    bottom: 0rem;
    height: 100%;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;

  }

  #bannerDesign {

    background-color: #00ffaa;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
		border-radius: 10px;
    
    height: 100%;
    position: relative;

  }

  .bannerDesign {

    display: flexbox;
    height: 99%;
    bottom: 0px;
    top: 0px;

  }

</style>