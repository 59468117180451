<template>
  <div class="contact">
    <h2>{{ posten }}</h2>
    <div class="data">
      <div class="name">
        {{ name }}
      </div>
      <div class="email" v-if="email">
        E-Mail: <a :href="'mailto:' + email">{{ email }}</a>
      </div>
      <div class="telefon" v-if="telefon">
        Telefon: <a :href="'tel:' + telefon">{{ telefon }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    posten: String,
    name: String,
    email: String,
    telefon: String
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped>

  *{
		user-select: text;
  }

  a {

    color: black;
    text-decoration: none;

  }

  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }

  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

  .contact {

    margin-top: 1.5rem;

  }

  .data {
    
    margin-top: 1rem;
    line-height: 1.5rem;

  }

</style>