<template>
    <h1>Termine</h1>
    <br/>
    <events-view></events-view>
</template>

<script>
import EventsView from "@/modules/eventModules/EventsView.vue";

export default {
    components: {EventsView}
}
</script>

<style scoped>
    h1 {
        text-align: center;
    }
</style>