<template>
  <events-view></events-view>
</template>

<script>
import EventsView from "@/modules/eventModules/EventsView.vue";

export default {
    name: "TestEvents",
    components: {EventsView}
}
</script>

<style scoped>

</style>