<template>
    <div class="itemBackground">
        <table>
            <tr>
                <th colspan="2">{{name}}</th>
            </tr>
            <tr v-if="width >= 800">
                <td><b>Wann ?</b><br>{{date}}<br>{{start_time}}</td>
                <td v-if="google_map_place">
                    <b>Wo ?</b><br>
                    <a v-bind:href="google_map_place">{{place}}<img src="@/assets/map-marker.png"/></a>
                </td>
                <td v-else>
                    <b>Wo ?</b><br>
                    {{place}}
                </td>
            </tr>
            <tr v-else>
                <td><br>
                    <b>Wann ?</b>
                    <br>
                    {{date}}<br>{{start_time}}<br><br>
                    <b>Wo ?</b><br>
                    <a v-if="google_map_place" v-bind:href="google_map_place">{{place}}<img src="@/assets/map-marker.png"/></a>
                    <div v-else>{{ place }}</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: "SingleEventItem",
    props: {
        name: String,
        place: String,
        google_map_place: String,
        date: String,
        start_time: String
    },
    data() {
        return {
            width: window.innerWidth
        }
    }
}
</script>

<style scoped>
  .itemBackground {
      background: white;
      width: 40vw;
      margin-left: auto;
      margin-right: auto;
      border-radius: 1vh;
      padding: 1.5vh;
  }

  .itemBackground table {
      width: 99%;
      margin-left: auto;
      margin-right: auto;
  }

  .itemBackground table th {
      /*font-size: clamp(1rem, 2vh, 2.3rem);*/
      font-size: 2vh;
      padding-bottom: 1vh;
  }

  .itemBackground table td {
      width: 50%;
      text-align: center;
  }

  img {
      height: 2vh;
      margin-top: 0.1vh;
      padding-left: 0.5vw;
  }

</style>