<template>
  <div v-if="imageZoom" class="backdrop" @click="imageClose"></div>
  <div id="homeMain">
    <table border="0">
      <tr>
        <td>
          <h2>Willkommen auf der Webseite des Fischerei-Verein Herten e.V.</h2>
        </td>
      </tr>
      <tr>
        <td>
          <img alt="Logo" :style="imageStyle" @click="imageToggle" src="../assets/Logo.png"/>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            Der Fischerei-Verein Herten e.V. wurde 1947 gegr&uuml;ndet und ist Mitglied bei dem Landesfischereiverband was uns erlaubt an verschiedenen 
            Gew&auml;ssern zu angeln (siehe <router-link to="/gewaesser">Gew&auml;sser</router-link>). Zus&auml;tzlich verf&uuml;gen wir &uuml;ber drei Vereins gew&auml;sser die nur 
            Vereins Mitglieder beangeln d&uuml;rfen (siehe <router-link to="/gewaesser">Gewässer</router-link>).
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageStyle: {},
      imageZoom: false
    }
  },
  methods: {

    imageToggle() {

      if(window.innerWidth <= 800)
          return;

      if(this.imageZoom) {

        this.imageZoom = false;
        this.imageStyle.transform = '';
        this.imageStyle.cursor = '';

      }else {

        this.imageZoom = true;

        this.imageStyle.transform = 'scale(2.0, 2.0)';
        this.imageStyle.cursor = 'zoom-out'

      }

    },
    imageClose() {

      if(this.imageZoom) {

        this.imageZoom = false;
        this.imageStyle.transform = '';
        this.imageStyle.cursor = '';

      }

    }

  }
}
</script>

<style scoped>

  a {

    color: #454545;
    text-decoration: underline;
    text-decoration-color: #454545;
    text-underline-offset: 2px;

  }

  a:link {
    color: #454545;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #454545;
    text-underline-offset: 2px;
  }

  a:visited {
    color: #454545;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #454545;
    text-underline-offset: 2px;
  }

  a:hover {
    color: #454545;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }

  a:active {
    color: #454545;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #454545;
    text-underline-offset: 2px;
  }

  h2 {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Calligraffitti', cursive;
    font-size: clamp(1.5rem, 4vw, 3rem);
  }

  tr, td {
    height: 33%;
    margin-left: auto;
    margin-right: auto;
  }

  table {
    height: 100%;
    width: 100%;
  }
  
  .backdrop {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

  }

  img {

    /*position: static;*/
    display: flex;
    height: 33vh;
    margin-left: auto;
    margin-right: auto;
    cursor: zoom-in;
    z-index: 100;

  }

  #homeMain {
    z-index: 1000;
		width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  #imageMiddle {
    
    position: static;
    display: flex;
    justify-content: center;
    align-content: center;
		background-repeat: no-repeat;
		background-position: center center;

    background-color: red;
  }

</style>