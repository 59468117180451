<template>
  <div id="HeaderDesign">
    <table border="0">
      <tr v-if="width >= 600">
        <th>
          <router-link to="/">Home</router-link>
        </th>
        <th>
          <router-link to="/contact">Kontakt</router-link>
        </th>
        <th>
          <router-link to="/events">Termine</router-link>
        </th>
        <th>
          <router-link to="/gewaesser">Gew&auml;sser</router-link>
        </th>
      </tr>
      <tr v-else id="HeaderMobilDesign">
        <th>
            <select name="Site" @change="selectNewPage($event)">
                <option value="/">Home</option>
                <option value="/contact">Kontakt</option>
                <option value="/events">Termine</option>
                <option value="/gewaesser">Gew&auml;sser</option>
            </select>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
    data() {
        return {
            width: window.innerWidth
        }
    },
    methods: {
        selectNewPage(event) {

            this.$router.push(event.target.value)

        }
    }
}
</script>

<style scoped>

  a {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }

  a:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: black;
    background-color: transparent;
    text-decoration: underline;
    text-decoration-color: #ff2a2a;
    text-underline-offset: 2px;
  }
  a:active {
    color: black;
    background-color: transparent;
    text-decoration: underline;
  }

  table {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  th {
    width: 25%;
  }

  div {

    width: 80%;
    margin-left: auto;
    margin-right: auto;

  }

  #HeaderDesign {
    
    width: 80%;
    height: 100%;
    background-color: #b4ffb4;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
    display: flex;
		justify-content: center;
		align-items: center;
		background-repeat: no-repeat;
		background-position: center center;

  }

  #HeaderMobilDesign select {

      width: 50%;
      background: transparent;

      padding: 5px;

      border-radius: 10px;

      text-align: center;

  }

</style>