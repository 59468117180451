import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';

import Banner from './modules/design/Banner.vue'

var VueApp = createApp(App)

VueApp.use(router);

VueApp.component('banner', Banner)

VueApp.mount('#app')
